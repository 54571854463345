import React from "react";
import './BannerHome.css';
import { TypeAnimation } from 'react-type-animation';
import { BsFillPlayBtnFill } from 'react-icons/bs';

function BannerHome() {
    return (
        <>
            {/* <div id="mySidenav" className="sidenav fixed-top">
                <a href="#" id="blog" className="trxt-dark">Contact</a>
            </div> */}
            <div className="bg-image">
            </div>
            <div className="bg-text">
                <div className="moto">

                    <div className="mototitle">
                        {/* <h1 className="fnt" style={{fontWeight:"700"}}>Committed to ensure full traceability, <br /> sustainability along with best quality</h1> */}
                        <TypeAnimation
                            // style={{
                            //     height: '200px',
                            //     width: '300px',
                            //     display: 'block',
                            //     border: 'solid 1px rgb(0,0,0)',

                            // }}
                            sequence={[' Committed to ensure full traceability, sustainability along with best quality ', 2000,]}
                            repeat={1}
                            speed={10}
                            cursor={false}
                        // sequence={[' IMPRO LECITHIN LLP Strives to offer quality Lecithin, Processed under world class Food Safty Standards through Extensive Quality Control Measures available at the state of the Art Processing factory and Laboratory. ', 2000,]}
                        // repeat={1}
                        // speed={10}
                        />
                        <p className="fnt mt-4 tophometext" ><b>IMPRO LECITHIN LLP</b> Strives to offer best quality NON GMO Lecithin, Processed under world class Food Safety Standards through Extensive Quality Control Measures available at the state of the Art Processing factory and Laboratory.</p>
                    </div>
                </div>
            </div>
            {/* <div className="text-center bg-dark" style={{padding:"100px", fontSize:"10rem", color:"white"}}>
            <BsFillPlayBtnFill  /> 
            </div> */}
            <div class="ratio ratio-16x9">
                <iframe src="https://www.youtube.com/embed/V7wF9hebydo?autoplay=1&mute=1&loop=1" title="YouTube video"
                    allow="autoplay"
                    allowfullscreen></iframe>
            </div>
        </>
    );

}

export default BannerHome;