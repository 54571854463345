// Pages
import AboutUs from "../pages/About/AboutUs";
import Certificate from "../pages/Certificates/Certificate";
import ContactUs from "../pages/ContactUs/ContactUs";
import Events from "../pages/Events/Events";
import Infrastructure from "../pages/Infrastructure/Infrastructure";
import Products from "../pages/Products/Products";

// import Ngsll from "../pages/Products/NGSLL/Ngsll";
// import Slfg from "../pages/Products/Ngsl/Slfg";
// import Ngslh from "../pages/Products/NGSLH/Sllfg";
// import Ngsl from "../pages/Products/Ngsl/Slfg";



const routes = [
    {
        name: "pages",
        columns: 1,
        rowsPerColumn: 2,
        collapse: [
            {
                name: "landing pages",
                collapse: [
                    {
                        name: "about us",
                        route: "/about-us",
                        component: <AboutUs />,
                    },
                 
                    {
                        name: "contact us",
                        route: "/contact-us",
                        component: <ContactUs />,
                    },
                      {
                        name: "infrastructure",
                        route: "infrastructure",
                        component: <Infrastructure />,
                      },
                      {
                        name: "events",
                        route: "events",
                        component: <Events />,
                      },
                      {
                        name: "certificates",
                        route: "certificates",
                        component: <Certificate />,
                      },
                      {
                        name: "products",
                        route: "products",
                        component: <Products />,
                      },
                ],
            },
        ],
    },
    // {
    //     name: "products",
    //     collapse: [
    //         {
    //             name: "products",
    //             description: "all products",
    //             dropdown: true,
    //             collapse: [
    //                 {
    //                     name: "NON GMO SOYA LECITHIN LIQUID",
    //                     route: "/products/ngsll",
    //                     component: <Ngsll />,
    //                 },
    //                 {
    //                     name: "SOYA LECITHIN LIQUID FOOD GRADE",
    //                     route: "/products/ngslh",
    //                     component: <Ngslh />,
    //                 },
    //                 {
    //                     name: "NON GMO SUNFLOWER LECITHIN",
    //                     route: "/products/ngsl",
    //                     component: <Ngsl />,
    //                 },
                    
                    
    //             ],
    //         },        
            
    //     ],
    // },
   
];

export default routes;
