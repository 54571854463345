import React from "react";
import BannerHome from "./BannerHome/BannerHome";
import Overview from "./Overview/Overview";
import Brand from "./Brand/Brand";
import Infrastructure from "./Infrastructure/Infrastructure";
import Testimonial from "./Testimonial/Testimonial";
// import Csr from "./CSR/Csr";
import Certifications from "./Certifications/Certifications";
import Usage from "./Usege/Usage";


function Home(){
    return(
        <>
         <BannerHome />
         <Overview />
         <Brand />
         {/* <Testimonial /> */}
         <Infrastructure />
         <Usage/>
         <Certifications />
         {/* <Csr /> */}
        </>
    );
}

export default Home;