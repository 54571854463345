import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { BsFacebook } from 'react-icons/bs';
import { BiLogoInstagramAlt } from 'react-icons/bi';
import { BsYoutube } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { BsTwitter } from 'react-icons/bs';
import { IoLogoWhatsapp } from 'react-icons/io';

function Header() {
    let topiconStyles = {
        color: "#0c72a8",
        fontSize: "1.5rem",
        height: "-webkit-fill-available",
    };
    return (
        <>
            <div className="container-fluid tophome">
                <div className="container">
                    <div className="d-flex justify-content-between  p-1">
                        <div className="d-flex ">
                            <div className="btn ms-2">
                                <Link to="https://www.facebook.com/people/IMPRO-Lecithin-LLP/61552536915490/">
                                <BsFacebook  style={topiconStyles}/>
                                </Link>
                            </div>
                            <div className="btn ms-0">
                                <Link to="https://www.instagram.com/improlecithinllp/">
                                <BiLogoInstagramAlt  style={topiconStyles}/>
                                </Link>
                            </div>
                            <div className=" btn ms-0">
                                <Link to="https://www.youtube.com/channel/UCJXiiXJzpJpJ_GXnMUs4t1g">
                                <BsYoutube  style={topiconStyles}/>
                                </Link>
                            </div>
                            <div className=" btn ms-0">
                                <Link to="https://www.linkedin.com/company/impro-lecithin-llp/?viewAsMember=true">
                                <BsLinkedin  style={topiconStyles}/>
                                </Link>
                            </div>
                            <div className=" btn ms-0">
                                <Link to="https://twitter.com/improlecithin" >
                                <BsTwitter  style={topiconStyles}/>
                                </Link>
                            </div>
                            <div className=" btn ms-0">
                               
                                <a href="https://wa.me/919754799799? text=Hi, i wanna ask about something?" target="_blank">
                                    <IoLogoWhatsapp style={topiconStyles}/>
                                    </a>
                                
                               
                            </div>
                        </div>
                        <div className="btn topemail me-4 " style={{color:"#0c72a8", fontWeight:"700",  }}>
                           
                            <Link to="mailto:info@improlecithin.com" style={{textDecoration:"none"}}>
                            <p className="fnt" >info@improlecithin.com</p>  
                            </Link> 
                                                     
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-light container">
                <div className="container-fluid ">
                    <Link to="/">
                    <a className="navbar-brand containerfluid" href="">
                        <img className="" src="../images/imprologonew.png"></img>
                    </a>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarScroll">
                        <div>
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll textcenter" >
                                <Link to="/">
                                    <li className="nav-item navitem me-3 navitem">
                                        <button className="fnt " id="abcsd" aria-current="page" href="#">Home</button>
                                    </li>
                                </Link>
                                <Link to="/about-us">
                                    <li className="nav-item navitem me-3 ">
                                        <button className=" fnt " id="abcsd" aria-current="page" href="#">About Us</button>
                                    </li>
                                </Link>
                                <Link to="/products">
                                    <li className="nav-item navitem me-3 ">
                                        <button className=" fnt " id="abcsd" aria-current="page" href="#"> Products</button>
                                    </li>
                                </Link>
                                
                                <Link to="infrastructure">
                                    <li className="nav-item navitem me-3">
                                        <button className="fnt  " id="abcsd" aria-current="page" href="#">Infrastructure</button>
                                    </li>
                                </Link>
                                <Link to="certificates" >
                                <li className="nav-item navitem me-3">
                                    <button className=" fnt " id="abcsd" aria-current="page" href="#">Certificates</button>
                                </li>
                                </Link>
                                <Link to="/events" >
                                    <li className="nav-item navitem me-3 navitem">
                                        <button className=" fnt " id="abcsd" aria-current="page" href="#">Events</button>
                                    </li>
                                </Link>
                                <Link to="/contact-us" >
                                    <li className="nav-item navitem me-3 navitem">
                                        <button className=" fnt " id="abcsd" aria-current="page" href="#">Contact Us</button>
                                    </li>
                                </Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;