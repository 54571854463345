import React from "react";
import "./Products.css";


function Products() {
    return (
        <>
            <div>
                <section className="titlebar columns ">
                    <div className="row1">
                        <div className="text-center mt-4 mb-3">
                            <h1 id="page-title" className="title fnt">Products</h1>
                            <div className=" text-center  ">

                            </div>
                        </div>
                    </div>
                </section>


                <div >
                    <div className="container">
                        <div className="row ">

                            <div className="col-sm-6 mt-5 ">
                                <div className="text-center mt-5">
                                    <div className="text-center">
                                        <h3 className="head-style inf fnt" >NON GMO SOYA LECITHIN LIQUID</h3>
                                    </div>

                                </div>
                                <div className="mt-5">
                                    <p className="fnt textalign">
                                    Soya lecithin liquid is a naturally occurring fatty substance extracted from soybeans during the soybean oil extraction process. It is a byproduct of soybean oil production and contains essential phospholipids like phosphatidylcholine, phosphatidylethanolamine, phosphatidylinositol, and phosphatidic acid, crucial components of cell membranes.
The liquid form of soya lecithin ranges from amber to brown in color and has a thick, viscous consistency. It possesses remarkable versatility, finding extensive applications across the food, pharmaceutical, cosmetic, and industrial sectors due to its emulsifying, stabilizing, and dispersing qualities. Serving as an emulsifier, it aids in blending and stabilizing mixtures of oil and water. In the realm of food production, it enhances texture, moisture retention, and extends the shelf life of various products.
Being a natural and plant-derived ingredient, soya lecithin liquid is often preferred over synthetic emulsifying agents. It is a ubiquitous component in numerous food items such as chocolate, baked goods, salad dressings, margarine, among others. Furthermore, it plays a significant role in the creation of dietary supplements, pharmaceuticals, and cosmetics, owing to its beneficial properties.
                                    </p>

                                </div>
                                <div className="text-center">
                                    <div className="thumbnail">
                                        <button type="button" className="btn  bgorange" data-bs-toggle="modal" data-bs-target="#imageExamplel"> Specifications</button>
                                        <div className="modal fade" id="imageExamplel" tabIndex="-1" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-body">
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                                                    </button>
                                                    <img src="./images/specification/specification1.jpg" className="w100h50 d-block w-100"></img>

                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mt-5">
                                <img src="./images/ngmos.jpg" alt="Nature" className="responsive w-100" />
                            </div>

                        </div>
                    </div>
                    <div style={{ height: "3px", backgroundColor: "orange", marginTop: "10px" }}></div>
                    <div className="container">

                        <div className="row ">
                            <div className="col-sm-6 mt-5">
                                <img src="./images/soyalhydrolyzed.jpg" alt="Nature" className="responsive w-100" />
                            </div>
                            <div className="col-sm-6 mt-5 ">
                                <div className="text-center mt-5">
                                    <div className="text-center">
                                        <h3 className="head-style inf fnt" >NON GMO SOYA LECITHIN HYDROLYZED</h3>
                                    </div>

                                </div>
                                <div className="mt-5">
                                    <p className="fnt textalign">
                                    HYDROLYZED SOYA LECITHIN is a derivative of soya lecithin, a fatty substance derived from soybeans. Soya lecithin is widely known for its emulsifying properties, making it valuable in various food and industrial applications where it helps blend and stabilize mixtures of fats and water.
The process of hydrolysis involves breaking down the molecular structure of soya lecithin into its constituent parts, often by adding water. This results in smaller molecules, enhancing the body's ability to absorb and utilize them. Hydrolyzed soya lecithin is frequently utilized in food and dietary supplements as a rich source of phospholipids, choline, and other essential nutrients.
In the food industry, hydrolyzed soya lecithin finds its use in improving the texture and extending the shelf life of food products. It is commonly found in an array of processed foods such as baked goods, chocolate, dairy products, sauces, dressings, and more.
                                    </p>
                                </div>
                                <div className="text-center">
                                    <div className="thumbnail">
                                        <button type="button" className="btn  bgorange" data-bs-toggle="modal" data-bs-target="#imageExamples"> Specifications</button>
                                        <div className="modal fade" id="imageExamples" tabIndex="-1" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-body">
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                                                    </button>
                                                    <img src="./images/specification/specification3.jpg" className="w100h50 d-block w-100"></img>

                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div style={{ height: "3px", backgroundColor: "orange", marginTop: "10px" }}></div>
                    <div className="container">

                        <div className="row ">
                            
                            <div className="col-sm-6 mt-5 ">
                                <div className="text-center mt-5">
                                    <div className="text-center">
                                        <h3 className="head-style inf fnt" >NON GMO SUNFLOWER LECITHIN</h3>
                                    </div>

                                </div>
                                <div className="mt-5">
                                    <p className="fnt textalign">
                                    Sunflower lecithin is a natural fatty substance extracted from sunflower seeds, specifically derived from the oil extracted during the sunflower oil production process. It is a byproduct of sunflower oil processing and is rich in phospholipids, similar to other lecithin sources like soybeans or eggs.

Sunflower lecithin is often used as an alternative to soy lecithin, especially for individuals with soy allergies or those who prefer non-GMO options. It shares similar properties to soy lecithin, serving as an effective emulsifier, stabilizer, and dispersing agent. Emulsifiers like sunflower lecithin help mix and stabilize ingredients that would naturally separate, like oil and water.
In various industries such as food, pharmaceuticals, cosmetics, and more, sunflower lecithin is used for its emulsifying properties. It's a common ingredient in food products like chocolates, baked goods, salad dressings, and margarine. As a plant-based and natural ingredient, it is often preferred in health-conscious and vegan-friendly applications.
                                    </p>
                                </div>
                                <div className="text-center">
                                    <div className="thumbnail">
                                        <button type="button" className="btn  bgorange" data-bs-toggle="modal" data-bs-target="#imageExamplee"> Specifications</button>
                                        <div className="modal fade" id="imageExamplee" tabIndex="-1" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-body">
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                                                    </button>
                                                    <img src="./images/specification/specification2.jpg" className="w100h50 d-block w-100"></img>

                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mt-5">
                                <img src="./images/sunflower.jpeg" alt="Nature" className="responsive w-100" />
                            </div>

                        </div>
                    </div>


                </div>
            </div>




            {/* <div className="row ">
                  <div className="col-sm-4 mt-5">
                     <div className="card">
                        <div className="image">
                           <img src="./images/improproductnewimg.jpg" />
                        </div>

                     </div>
                     <div className="card-inner mt-4">
                        <div className="header brandtitle text-center">
                           <h4 className="fnt" > NON GMO SOYA LECITHIN HYDROLYZED</h4>

                        </div>
                        <div className="mt-5">
                            <p className="fnt">
                                Hydrolyzed soy lecithin is a derivative of soy lecithin, which is a fatty substance obtained from soybeans. Soy lecithin is typically used as an emulsifier in various food and industrial applications due to its ability to blend and stabilize mixtures of fats and water.

                                <br />   Hydrolysis involves breaking down the molecular structure of soy lecithin into its constituent parts, usually through the
                                addition of water. This process results in smaller molecules, making it easier for the body to absorb and utilize. Hydrolyzed soy lecithin is often used in food and dietary supplements as a source of phospholipids, choline, and other essential nutrients.

                                <br />   In the food industry, hydrolyzed soy lecithin is used to enhance the texture and shelf life of food products. It can be found in a variety of processed foods such as baked goods, chocolate, dairy products, sauces, dressings, and more.

                                <br />   It's important to note that some individuals may be allergic to soy, so they should avoid products containing soy or its derivatives, including hydrolyzed soy lecithin, if they have a soy allergy. Always check product labels if you have dietary restrictions or allergies.
                            </p>
                        </div>

                     </div>
                  </div>
                  <div className="col-sm-4 mt-5">
                     <div className="card">
                        <div className="image">
                           <img src="./images/improproduct2.jpg" />
                        </div>

                     </div>
                     <div className="card-inner  mt-4">
                        <div className="header brandtitle text-center">
                           <h4 className="fnt" > NON GMO SOYA LECITHIN LIQUID</h4>

                        </div>
                        <div className="mt-5">
                            <p className="fnt">
                                Hydrolyzed soy lecithin is a derivative of soy lecithin, which is a fatty substance obtained from soybeans. Soy lecithin is typically used as an emulsifier in various food and industrial applications due to its ability to blend and stabilize mixtures of fats and water.

                                <br />   Hydrolysis involves breaking down the molecular structure of soy lecithin into its constituent parts, usually through the
                                addition of water. This process results in smaller molecules, making it easier for the body to absorb and utilize. Hydrolyzed soy lecithin is often used in food and dietary supplements as a source of phospholipids, choline, and other essential nutrients.

                                <br />   In the food industry, hydrolyzed soy lecithin is used to enhance the texture and shelf life of food products. It can be found in a variety of processed foods such as baked goods, chocolate, dairy products, sauces, dressings, and more.

                                <br />   It's important to note that some individuals may be allergic to soy, so they should avoid products containing soy or its derivatives, including hydrolyzed soy lecithin, if they have a soy allergy. Always check product labels if you have dietary restrictions or allergies.
                            </p>
                        </div>
                     </div>
                  </div>


                  <div className="col-sm-4 mt-5">
                     <div className="card">
                        <div className="image">
                           <img src="./images/improproductimgnn.jpg"  style={{height:"442px"}}/>
                        </div>

                     </div>
                     <div className="card-inner  mt-4">
                        <div className="header brandtitle text-center">
                           <h4 className="fnt" > NON GMO SUNFLOWER LECITHIN</h4>

                        </div>
                        <div className="mt-5">
                            <p className="fnt">
                                Hydrolyzed soy lecithin is a derivative of soy lecithin, which is a fatty substance obtained from soybeans. Soy lecithin is typically used as an emulsifier in various food and industrial applications due to its ability to blend and stabilize mixtures of fats and water.

                                <br />   Hydrolysis involves breaking down the molecular structure of soy lecithin into its constituent parts, usually through the
                                addition of water. This process results in smaller molecules, making it easier for the body to absorb and utilize. Hydrolyzed soy lecithin is often used in food and dietary supplements as a source of phospholipids, choline, and other essential nutrients.

                                <br />   In the food industry, hydrolyzed soy lecithin is used to enhance the texture and shelf life of food products. It can be found in a variety of processed foods such as baked goods, chocolate, dairy products, sauces, dressings, and more.

                                <br />   It's important to note that some individuals may be allergic to soy, so they should avoid products containing soy or its derivatives, including hydrolyzed soy lecithin, if they have a soy allergy. Always check product labels if you have dietary restrictions or allergies.
                            </p>
                        </div>
                     </div>
                  </div>
               </div> */}


        </>
    );

}

export default Products;