import React from "react";
import "./Overview.css";
// import { MdOutlineChecklist } from 'react-icons/md';
import { AiOutlineContacts } from 'react-icons/ai';
import { LuAward } from 'react-icons/lu';
import { BsFlag } from 'react-icons/bs';
import { MdOutlineRememberMe } from 'react-icons/md';


function Overview() {
    let iconStyles = {
        color: "orange",
        fontSize: "2.5rem",
        height: "-webkit-fill-available",
    };
    return (
        <>
            <div className="overview">
                <div className="container ">
                    <div className="row">
                        {/* <div className="col-4 container mt-5">
                            <div className="img-over">
                                <img src="./images/improimg/improimg (6).jpg"></img>
                            </div>
                        </div> */}
                        <div className="col mt-5 colmt5">
                            <h2 style={{    fontFamily: 'uni_neuebook'}} className="fnt"> <span className="textyellow fnt" >Overview</span></h2>
                            <p className="mt-4 fontfamily fnt textalign" >Welcome to IMPRO LECITHIN LLP, an eminent innovator in the realm of Lecithin Products. 
                            We stand as pioneers in this industry, wholeheartedly committed to leveraging the potential of lecithin to craft top-notch, 
                            sustainable, and adaptable products catering to a diverse spectrum of applications. Our unwavering dedication to achieving excellence, 
                            our continuous pursuit of cutting-edge research, and our unwavering focus on ensuring customer satisfaction collectively establish us as 
                            a leading entity in the global market.
                            </p> <br />
                            <p className="fnt whywork">
                            WHY WORK WITH IMPRO LECITHIN LLP ? <br /> </p>
                           <p className="fontfamily fnt textalign"> You may be wondering why our company is the best option when it comes to choosing a Lecithin supplier.
                            Here are a few of the top reasons that we should be your choice manufacturer and supplier :
                            </p>
                            <div className="mt-4">
                                {/* <MdOutlineChecklist /> */}
                                <ul>
                                    <li className="fontfamily fnt textalign"><b>We only work with “LECITHIN”  :</b> As the only company that is fully dedicated to working with Lecithin products, we are extremely knowledgeable about the industry and how to make our products of the highest quality possible.</li>
                                    <li className="fnt "> <b>We are certified NON GMO :</b> We know the importance of having the most up-to-date certifications in the industry, 
                                    and  we are proud to hold a number of certifications for our products and processes. We have a number of different certifications aside from being NON GMO,
                                     so take a look at our Certifications page to learn more about our Lecithin products.</li>
                                    <li className="fnt textalign"><b>We have the experience you need :</b>  We have been working with Lecithin since 1996. We used to offer more products,
                                     but we saw the need for a company specializing in only Lecithin and dedicated our entire business to them.</li>
                                    <li className="fnt textalign"><b>We get in touch quickly : </b> When you reach out to us, we will get back to you within one business day. We know how important
                                     it is to have lecithin supplier that will be easy to reach and communicate with, and we are happy to offer high-quality customer service for our clients.</li>
                                </ul>

                            </div>
                        </div>

                        {/* <div className="col mt-5">
                            <div>
                                <div className="d-flex dflex">
                                    <div className="icon-border">
                                        <AiOutlineContacts style={iconStyles} />
                                    </div>
                                    <div className="">
                                        <h3>0</h3>
                                        <h3>Happy Clients</h3>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex dflex dflex1">
                                        <div className="icon-border">
                                            <LuAward style={iconStyles} />
                                        </div>
                                        <div className="">
                                            <h3>0</h3>
                                            <h3>Awards Win</h3>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex dflex">
                                        <div className="icon-border">
                                            <BsFlag style={iconStyles} />
                                        </div>
                                        <div className="">
                                            <h3>0</h3>
                                            <h3>Finished Work</h3>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <div className="d-flex dflex">
                                        <div className="icon-border">
                                            <MdOutlineRememberMe style={iconStyles} />
                                        </div>
                                        <div className="">
                                            <h3>0</h3>
                                            <h3>Team Members</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> */}


                    </div>
                </div>
            </div>
        </>
    );
}

export default Overview;