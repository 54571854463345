import React, { useEffect } from "react";
import "./Footer.css";
import { BiLogoFacebook } from 'react-icons/bi';
import { BiLogoInstagram } from 'react-icons/bi';
import { BsYoutube } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { BsTwitter } from 'react-icons/bs';
import { IoLogoWhatsapp } from 'react-icons/io';

import { Link } from "react-router-dom";

// useEffect(() => {
//     window.scrollTo(0, 0)
//   }, []);


// scrollToTop = () => window.scrollTo(0, 0);

// function scrollToTop(){
//     return(
//         <>
//         window.scrollTo(0, 0);
//         </>
//     )
// }


function Footer() {

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };


    let iconStylesfoot = {
        color: "orange",
        fontSize: "1.5rem",
        // height: "-webkit-fill-available",
    };
    return (
        <>
            <div className=" mt-4">
                <footer
                    className="text-center text-lg-start text-white"
                    style={{ backgroundColor: "#252422" }}
                >
                    <section className=" ">
                        <div className="container text-center text-md-start ">

                            <div className="row ">
                                <div className="col-md-2 col-lg-2 col-xl-4 mx-auto mb-md-0 mb-4 mt-4">

                                    <h6 className="text-uppercase fw-bold fnt">REACH US</h6>
                                    <hr
                                        className="mb-4 mt-0 d-inline-block mx-auto"
                                        style={{ backgroundColor: "orange", width: "60px", height: "2px" }}

                                    />
                                    <p className="fnt"> IMPRO LECITHIN LLP </p>
                                    <p className="fnt"><i className="fas fa-home mr-3"></i> SURVEY No. 157/2 VILLAGE SHAHNA,
                                        POST DHARAMPURI, DIST. INDORE - 453771
                                        INDIA <img src="./images/flagind.png"  style={{width:"20px"}}></img> </p>
                                    <p className="fnt"><i className="fas fa-envelope mr-3"></i> <Link to="mailto:info@improlecithin.com" style={{textDecoration:"none", color:"white"}}> info@improlecithin.com </Link> <br /><Link to="mailto:info@improlecithin.com" style={{textDecoration:"none", color:"white"}}>  improlecithinllp@gmail.com</Link>
                                    </p>
                                    
                                    <p className="fnt"><i className="fas fa-phone mr-3"></i> +91 9754 799 799</p>
                                    {/* <p className="fnt"><i className="fas fa-print mr-3"></i> +91 9754799799</p> */}
                                    <div>
                                        <Link to="https://www.facebook.com/people/IMPRO-Lecithin-LLP/61552536915490/" className="text-white me-4">
                                            <BiLogoFacebook style={iconStylesfoot} />
                                        </Link>
                                        <Link to="https://www.instagram.com/improlecithinllp/" className="text-white me-4">
                                            <BiLogoInstagram style={iconStylesfoot} />
                                        </Link>
                                        <Link to="https://www.youtube.com/channel/UCJXiiXJzpJpJ_GXnMUs4t1g" className="text-white me-4">
                                            <BsYoutube style={iconStylesfoot} />
                                        </Link>
                                        <Link to="https://www.linkedin.com/company/impro-lecithin-llp/?viewAsMember=true" className="text-white me-4">
                                            <BsLinkedin style={iconStylesfoot} />
                                        </Link>
                                        <Link href="https://twitter.com/improlecithin" className="text-white me-4">
                                            <BsTwitter style={iconStylesfoot} />
                                        </Link>
                                        <a href="https://wa.me/919754799799? text=hi, i wanna ask about something?" className="text-white me-4">
                                            <IoLogoWhatsapp style={iconStylesfoot} />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 mt-4">

                                    <h6 className="text-uppercase fw-bold">QUICK LINKS</h6>
                                    <hr
                                        className="mb-4 mt-0 d-inline-block mx-auto"
                                        style={{ backgroundColor: "orange", width: "60px", height: "2px" }}

                                    />
                                    <Link to="/" className="footlink">
                                        <p className="fnt">
                                            <IoIosArrowDroprightCircle style={iconStylesfoot} />
                                            <a href="" className="text-white foot-style ms-2" onClick={scrollToTop}>Home</a>
                                        </p>
                                    </Link>
                                    <Link to="/about-us" className="footlink">
                                        <p className="fnt">
                                            <IoIosArrowDroprightCircle style={iconStylesfoot}  />     <a href="" className="text-white foot-style" onClick={scrollToTop}>About Us</a>
                                        </p>
                                    </Link>

                                    <Link to="/infrastructure" className="footlink">
                                        <p className="fnt">
                                            <IoIosArrowDroprightCircle style={iconStylesfoot} />  <a href="" className="text-white foot-style" onClick={scrollToTop}>Infrastructure</a>
                                        </p>
                                    </Link>
                                    <Link to="certificates" className="footlink">
                                        <p className="fnt">
                                            <IoIosArrowDroprightCircle style={iconStylesfoot} />  <a href="" className="text-white foot-style" onClick={scrollToTop}>Certificates</a>
                                        </p>
                                    </Link>
                                    <Link to="events" className="footlink">
                                        <p className="fnt">
                                            <IoIosArrowDroprightCircle style={iconStylesfoot} />  <a href="" className="text-white foot-style" onClick={scrollToTop}>Events</a>
                                        </p>
                                    </Link>
                                    <Link to="contact-us" className="footlink">
                                        <p className="fnt">
                                            <IoIosArrowDroprightCircle style={iconStylesfoot} />  <a href="" className="text-white foot-style" onClick={scrollToTop}>Contact Us</a>
                                        </p>
                                    </Link>
                                </div>
                                <div className="col-md-2 col-lg-2 col-xl-4 mx-auto mb-4 mt-4">

                                    <h6 className="text-uppercase fw-bold">OUR PRODUCTS</h6>
                                    <hr
                                        className="mb-4 mt-0 d-inline-block mx-auto"
                                        style={{ backgroundColor: "orange", width: "60px", height: "2px" }}

                                    />
                                    <Link to="products" className="footlink">
                                    <p className="">
                                        <IoIosArrowDroprightCircle style={iconStylesfoot} /> <a href="" className="text-white foot-style ipro" onClick={scrollToTop}>NON GMO SOYA LECITHIN LIQUID</a>
                                    </p>
                                    </Link>
                                    <Link to="products" className="footlink">
                                    <p className="fnt">
                                        <IoIosArrowDroprightCircle style={iconStylesfoot} />   <a href="" className="text-white foot-style ipro" onClick={scrollToTop}>NON GMO SOYA LECITHIN HYDROLYZED</a>
                                    </p>
                                    </Link>
                                    <Link to="products" className="footlink">
                                    <p className="fnt">
                                        <IoIosArrowDroprightCircle style={iconStylesfoot} />  <a href="" className="text-white foot-style ipro" onClick={scrollToTop}>NON GMO SUNFLOWER LECITHIN</a>
                                    </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div
                        className="text-center p-3"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}

                    >
                        © 2023 Copyright:
                        <a className="text-white" href="https://improlecithin.com/"
                        >improlecithin.com</a
                        >
                    </div>
                </footer>
            </div>
        </>
    );

}

export default Footer;