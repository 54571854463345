import logo from './logo.svg';
import './App.css';
import routes  from './Routes/routes';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Header from './layouts/Header/Header';
import Home from './pages/Home';
import Footer from './layouts/Footer/Footer';

function App() {

  const getRoutes = (allRoutes) =>
  
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });


  return (
    <div >
        <Header />
        <Routes>
        
        {getRoutes(routes)}
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
