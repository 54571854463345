import React from "react";
import "./Brand.css";
import { Link } from "react-router-dom";

function Brand() {
   const scrollToTop = () => {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  };

   return (
      <>

         <div className="productshomw">


            <div className="container ">
               <div className="text-light text-center ">
                  <h3 className="head-style fnt" >Our Products</h3>

               </div>
               <div className="row ">
                  <div className="col-sm-4 mt-5 hoverimgb">
                  <Link to="/products" >
                     <div className="card " onClick={scrollToTop}>
                        <div className="image ">
                           <img src="./images/improproductnewimg.jpg" />
                        </div>

                     </div>
                     <div className="card-inner mt-4">
                        <div className="header brandtitle text-center">
                           <h4 className="fnt" > NON GMO SOYA LECITHIN HYDROLYZED</h4>

                        </div>

                     </div>
                     </Link>
                  </div>
                  <div className="col-sm-4 mt-5 hoverimgb">
                  <Link to="/products" >
                     <div className="card" onClick={scrollToTop}>
                        <div className="image ">
                           <img src="./images/ngmos.jpg" />
                        </div>

                     </div>
                     <div className="card-inner  mt-4">
                        <div className="header brandtitle text-center">
                           <h4 className="fnt" > NON GMO SOYA LECITHIN LIQUID</h4>

                        </div>

                     </div>
                     </Link>
                  </div>


                  <div className="col-sm-4 mt-5 hoverimgb">
                  <Link to="/products" >
                     <div className="card" onClick={scrollToTop}>
                        <div className="image ">
                           <img src="./images/sunflowerimpro.jpg" className="newproing" />
                        </div>

                     </div>
                     
                     <div className="card-inner  mt-4">
                        <div className="header brandtitle text-center">
                           <h4 className="fnt" > NON GMO SUNFLOWERLECITHIN</h4>

                        </div>

                     </div>
                     </Link>
                  </div>
               </div>
            </div>
         </div>

         {/* <div className=" brand-section">
            <div className="text-light text-center pt-5">
               <h3 className="head-style">Our Products</h3>
               <div className="mt-4">
                  <p>At IMPRO we believe in strong evolution of sustainable ingredients with specialized applications for needs of food, health and feed industry.</p>
               </div>
            </div>
            <div className="row  text-light row-pad container">
               <div className="col">
                  <div className="main-col-cont">
                     <div type="button " className="container colcontain">
                        <span>01</span>
                        <h4 className="n-g-s-l "> NON GMO SOYA LECITHIN LIQUID</h4>
                     </div>
                     <div className=" brand-card container">
                        <img className="brand-images w-100" src="./images/non-gmo-soya-lecithin1.jpg"></img>
                     </div>
                  </div>
               </div> 
               <div className="col">
                  <div className="main-col-cont2">
                     <div type="button " className="container colcontain">
                        <span>02</span>
                        <h4 className="fnt" >  NON GMO SOYA LECITHIN HYDROLYZED</h4>
                     </div>
                     <div className=" brand-card container">
                        <img className="brand-images w-100" src="./images/sllfg.jpeg"></img>
                     </div>
                  </div>
               </div>
               <div className="col">
                  <div className="main-col-cont">
                     <div type="button " className="container colcontain">
                        <span>03</span>
                        <h4 className="fnt" >  NON GMO SUNFLOWER LECITHIN</h4>
                     </div>
                     <div className=" brand-card container">
                        <img className="brand-images w-100" src="./images/hslln.jpeg"></img>
                     </div>
                  </div>
               </div>
            </div>
         </div> */}
         {/* <div className="container mt-5 ">
            <div className="text-center">
               <div></div>
               <h3 className="head-style inf">Brnad</h3>
            </div>
            <div className="row justify-contant-between">
               <div className="col-sm-4">
                  <img className=" w-75" src="./images/sllfg.jpeg"></img>
               </div>
               <div className="col-sm-4">
                  <img className=" w-75" src="./images/sllfg.jpeg"></img>
               </div>
               <div className="col-sm-4">
                  <img className=" w-75" src="./images/hslln.jpeg"></img>
               </div>
            </div>
         </div> */}
      </>
   );
}

export default Brand;