import React from "react";
import "./Usage.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function Usage() {

    return (
        <>
            
            {/* new one */}

            <div className="mt-5">
                <div className="text-center container ">
                    <h3 className="head-style inf fnt">Usage Of Lecithin</h3>
                    <div className="mt-4">
                        <p className="fnt">
                            Lecithin is a versatile and widely used natural emulsifier and surfactant derived from various sources,
                            most commonly soybeans, sunflowers and eggs. Its unique properties make it a valuable ingredient in a wide
                            range of industries and applications.

                        </p>
                        
                    </div>
                </div>
                <div className="container">
                <Carousel
                    showArrows={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={false}
                    autoPlay={true}
                    interval={1500}
                    dots={true}
                    showIndicators={false}
                // data-interval="10"


                >
                    <div className=" usagecarousel ">
                        <img src="/images/usage/animalusag.jpeg" className="img-fluid " alt="impro" style={{ width: "100%" }}  />
                        <div className="carousel-caption d-none d-md-block  carouselcaption">
                            <h4 className="usagetittle fnt text-dark">
                                Animal Feed and Agriculture
                            </h4>
                            <p className="fnt text-dark textalign">
                                Lecithin is added to animal feed to improve nutrient absorption and support overall animal health.</p>
                        </div>

                        
                    </div>
                    <div className=" usagecarousel">

                    <img src="/images/usage/foodusag.jpeg" className="img-fluid" alt="impro" style={{ width: "100%" }} />
                    <div className="carousel-caption d-none d-md-block  carouselcaption">
                            <h4 className="usagetittle fnt text-dark">
                            Food and Beverage Industry
                            </h4>
                            <p className="fnt text-dark textalign">
                            Lecithin is used to create stable emulsions, preventing separation of ingredients in products like mayonnaise, salad dressings, and sauces. It improves dough elasticity, texture, and moisture retention in baked goods like bread, cakes, and pastries. Lecithin aids in blending cocoa and fats, ensuring smoothness and preventing chocolate from blooming. In plant-based milk products, lecithin can stabilize the fat and water components.</p>
                        </div>
                    </div>

                    <div className=" usagecarousel">

                    <img src="/images/usage/pharmacueticalusag.jpeg" className="img-fluid" alt="impro" style={{ width: "100%" }} />
                    <div className="carousel-caption d-none d-md-block  carouselcaption">
                            <h4 className="usagetittle fnt text-dark">
                            Pharmaceutical and Nutraceutical Industry
                            </h4>
                            <p className="fnt text-dark textalign">
                            Lecithin acts as a carrier for poorly water-soluble drugs, improving their absorption and bioavailability. Lecithin is used in supplements to support brain health, liver function, and overall lipid metabolism due to its high phospholipid content.</p>
                        </div>
                    </div>

                    <div className=" usagecarousel">

                    <img src="/images/usage/cosmaticusag.jpeg" className="img-fluid" alt="impro" style={{ width: "100%" }} />


                    <div className="carousel-caption d-none d-md-block  carouselcaption">
                            <h4 className="usagetittle fnt text-dark">
                            Cosmetics and Personal Care
                            </h4>
                            <p className="fnt text-dark textalign">
                            Lecithin is used to stabilize oil-in-water and water-in-oil emulsions in products like lotions, creams, and serums. It helps achieve desired textures and spreadability in various cosmetic formulations. Lecithin can be found in hair conditioners to improve manageability and reduce frizz.</p>
                        </div>
                    </div>

                    <div className=" usagecarousel">

                    <img src="/images/usage/industryusag.jpeg" className="img-fluid" alt="impro" style={{ width: "100%" }} />


                    <div className="carousel-caption d-none d-md-block  carouselcaption">
                            <h4 className="usagetittle fnt text-dark">
                            Industrial Applications
                            </h4>
                            <p className="fnt text-dark textalign">
                            Lecithin-based lubricants are used in various industries for their eco-friendly and bio-based properties. In printing inks, lecithin improves ink flow and stability. Lecithin can be used in coatings to enhance adhesion and dispersion of pigments.</p>
                        </div>
                    </div>

                    <div className=" usagecarousel">

                    <img src="/images/usage/medicinusag.jpeg" className="img-fluid" alt="impro" style={{ width: "100%" }} />

                    <div className="carousel-caption d-none d-md-block  carouselcaption">
                            <h4 className="usagetittle fnt text-dark">
                            Medicine and Health Products
                            </h4>
                            <p className="fnt text-dark textalign">
                            Lecithin is used in certain medical treatments to aid in fat metabolism and support liver function. Lecithin is a source of choline, an essential nutrient important for brain health and nerve function.</p>
                        </div>
                    </div>
                </Carousel>
                </div>
                
            </div>



        </>
    );
}

export default Usage;